.steps {
    padding-top: 60px;
    padding-bottom: 60px;
}

.process {
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url(../images/iPhone.jpg);
    background-position: center;
    background-size: cover;
}

.homepageNav {
    background-color: white !important;
}

.homeHr {
    color: lightgray !important;
}

.winCard {
    border-color: #ecf0f5 !important;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
    min-height: 250px;
}

.winCardImage {
    max-width: 50px;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    border-radius: 50px;
    border: 1px solid #dee2e6;
    padding: 3px;
}