.CodeMirror {
  height: 100%;
  overflow: auto;
}

.codox-container {
  padding-top: 1em !important;
  justify-content: flex-start !important;

  .codox-avatar-initials {
    margin-left: .25em;
  }
}

[aria-label='Accept all changes'], [aria-label='Reject all changes'], [aria-label='Accept Change'], [aria-label='Reject Change'], [aria-label='Toggle Tracking Changes'] {
  display: none !important;
}

.fr-second-toolbar {
  display: none !important;
}

.legislation-SectionText {
  line-height: 1.8;
  font-size: 1.3em;
  overflow-wrap: break-word;
  text-indent: 2em;
}

.legislation-SectionTitle {
  line-height: 1.8;
  font-family: $legal-font;
  font-size: 1.4em;
  text-transform: uppercase;
  overflow-wrap: break-word;
}

.legislation-title {
  line-height: 1.8;
  font-family: $legal-font;
}

.legislation-subtitle {
  line-height: 1.8;
  font-family: $legal-font;
  font-style: italic;
  font-size: 1.2em;
  overflow-wrap: break-word;
}

.legislation-background {
  background-color: $white;
  box-shadow: 0 4px 8px -4px rgba(0,0,0,0.4);
  border-radius: 0.2rem
}
#firepad-container {
  width: 100%;
  height: 555px;
  margin: auto;
}

a.powered-by-firepad {
  display: none;
}