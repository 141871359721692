.editor-markdown,
.editor-input,
.editor-date,
.editor-number,
.editor-textarea {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0.25rem !important;
}

.editor-markdown {
  min-height: 100px;
}

.editor-title{
  color: rgb(102, 110, 125);
  display:inline-block; 
  background-color: rgb(213, 213, 217);
  border-radius: 0.25rem 0.25rem 0 0;
  margin-bottom: 0;
  min-width: 150px;
  font-size: 1.2em;
}

.editor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.editor-controls {
  font-family: lato, Times New Roman;;
  font-size: 10pt;
  margin-bottom: 5px;
  user-select: none;
}

.editor-activeButton {
  color: #5890ff;
}

.editor-hint {
  font-size: 0.75em;
  color: rgb(122, 130, 145);
  user-select: none;
}

.editor-split-pane {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.editor-split-pane-left,
.editor-split-pane-right {
  flex: 1;
}

.editor-split-pane-right {
  background-color: $light-gray;
  width: 100%;
  overflow: auto;
}

.editor-separator {
  width: 10px;
  background-color: #BBBBBB;
  border: 4px solid  #D8D8D8;
  cursor: col-resize;
  margin-top: 2px;
  margin-bottom: 2px;
}

.tox-statusbar {
  display: none !important;
}