.competition-card {
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.35s ease-in-out;
}

.competition-card:hover {
    box-shadow: 0 4px 13px -4px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.35s ease-in-out;
}
.half-a-border-on-top {
    border-top: 1px solid black;
    position: relative;
}
.half-a-border-on-top:after {
    padding: 0;
    margin: 0;
    display: block; /* probably not really needed? */
    content: "";
    width: 60%;
    height: 19px; /* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
    background-color: #FFFF;
    position: absolute;
    border-radius:0 10px 10px 0 ;
    right: 0;
    top: -10.5px;
}
.unstyled-link {
    text-decoration: none;
    color: black;
}

.unstyled-link:hover {
    text-decoration: none;
    color: black;
}

.competition-card-header {
    background-color: $turq-primary !important;
    color: white;
    min-height: 95px;
}

.contest-legislation-list,
.contest-background {
    background-color: $white;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.4);
    border-radius: 0.2rem
}

.left-contest-panel {
    min-height: 400px;
    background-color: $white;
    border: 2px solid $medium-gray;
}
.right-contest-panel {
    min-height: 400px;
    background-color: $light-gray;
    border: 2px solid $medium-gray;
}

.checkout-highlighted {
    border-radius: 8rem !important;
}

.contest-card {
    width: 100%;
    margin: 16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contest-card-content {
    width: 100%;
    height: 100%;
    margin: 16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .issueCard {
    backgroundColor: white;
    width: 80%;
} */

.title-status-raised-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    top: -5px;
  }  

.issueTitleContainer {
    flex-grow: 1;
    max-width: 50%;
  }

.issueStatusContainer {
    flex-grow: 1;
    max-width: 25%;
  }

.issueRaisedContainer {
    flex-grow: 1;
    max-width: 25%;
  }

.middleSection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

.detailsSection {
    opacity: 0.7;
    border-radius: 1%;
    position: relative;
    left: 10px;
    top: 10px;
}

.copyButton {
    padding: 8px 10px;
    border-radius: 50%;
    text-align: center;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 1px #666;
    position:absolute;
    right: -2%;
    top: -5%;
    z-index: 99;
}

.copyButton:hover {background-color: #EBECF0}

.editFab {
    position:absolute;
    right: -98%;
    bottom: -20px;
    z-index: 99;
}

.addLegislationFab {
    position:absolute;
    right: 45%;
    top: 102%;
    z-index: 99;
}

.viewIssueButton {
    padding: 5px 5px;
    font-size: 60%;
    position:absolute;
    right: 5%;
    top: 105%;
    z-index: 99;
}