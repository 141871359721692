.hero {
    margin: 0;
    min-height: 100vh;
    background: linear-gradient(to bottom, #E3F2FD, #A0C4FF);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.heroTextBox {
    background: none;
    padding: 80px 30px;
    max-width: 800px;
}

.heroTextBox h1 {
    font-size: 3.5em;
    font-weight: 800;
    color: #1C3D5A;
    text-align: center;
}

.heroTextBox p {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #2A3F4F;
    max-width: 650px;
    margin: 10px auto;
}

.cta-button {
    display: inline-block;
    margin: 40px auto;
    padding: 15px 40px;
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    border-radius: 30px;
    background-color: #1C3D5A;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border: 3px solid #ffffff; // ✅ White border added
}

.cta-button:hover {
    background-color: #374151;
    border-color: #ffffff;
}

.heroVideoBox {
    background: #295A77;
    padding: 80px 0;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .heroTextBox {
        padding: 50px 20px;
        max-width: 90%;
    }

    .heroTextBox h1 {
        font-size: 2.5em;
    }

    .heroTextBox p {
        font-size: 1.2rem;
    }

    .cta-button {
        font-size: 1.3em;
        padding: 12px 30px;
    }

    .heroVideoBox {
        padding: 50px 0;
    }
}
