.support-hero {
    width: 100%;
    min-height: 457px;
    position: relative;
    &::after {
        content: "";
        background-image: url("/images/hero_image.jpeg");
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
    .intro{
        position: absolute;
        top: 62px;
        right: 8%;
        width: 38%;
        //height: 350px;
        padding: 8px 25px 8px 25px; 
        border-radius: 10px;
        display: flex;
        align-items: center;
        overflow: hidden;
        font-style: bold;
        font-size: 1.875rem;
        @media screen and (max-width: 768px){
            font-size: 1.2rem;
            position: static;
            width: 100%;
            border-radius: 0;
        }
        p{
            margin: 0;
            padding: 17px 0;
            line-height: 40px;
        }
    }
    .checkout-page{
        position: absolute;
        left: 8.5%;
        top: 120px;
        width: 35%;
        height: 660px;
        padding: 0;
        border: 10px solid black;
        @media screen and (max-width: 768px){
            position: relative;
            left: auto;
            top: auto;
            width: 100%;
            border: none;
        }
        .MuiCardHeader-title{
            font-weight: bold;
            text-align: left;
        }
        .powered{
            position: absolute;
            bottom: -39px;
            @media screen and (max-width: 768px){
                bottom: -26px;
            }
        }
    }
}
.support-body {
    width: 100%;
}

.support-body-header{
    width: 37%!important;
    min-width: 342px;
    height: 181px;
    margin-top: 458px;
    @media screen and (max-width: 768px){
        margin-top: 100px;
    }
    .title{
        font-weight: bold;
    }
    .extra{
        font-size: 22px;
        color: #a6a6a6;
    }
}
  
.support-body-subwrap{
    margin-top: 65px;
    .sub{
        width: 262px;
        height: 162px;
        margin-left: 32px;
        margin-right: 32px;
        div:first-child{
            overflow: hidden;
            height: 60px;
            width: 74px;
        }
        img{
            width: 74px;
            height: 70px;
        }
        .desc{
            margin-top: 14px;
            height: 78px;
        }
    }
}

.support-mid-section{
    margin-top: 100px;
    margin-bottom: 27px;
    .info{
        font-weight: bold;
        width: 221px;
        line-height: 55px;
        background: #ffffff; 
    }
}
