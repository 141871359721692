.checkout-card {
    width: 100%;
    margin: 16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkout-page {
  width: 100%;
  padding: 40px;
}