@import "./_base.scss";

@font-face {
    font-family: montserrat;
    src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: montserratBold;
    src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: openSans;
    src: url('../fonts/OpenSans-Regular.ttf');
}

html {
    overflow-y: scroll;
}

body {
    word-wrap: break-word;
    font-family: openSans, serif !important;
    color: black !important;
    background-color: #f8f9fc;
}

h1, h2, h3 {
    font-family: montserratBold;
}

h4, h5 {
    font-family: montserrat;
}

h1 {
    font-size: 48px;
}

.site {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}



@import "./_drafter.scss";
@import "./_legislation.scss";
@import "./_explainer.scss";
@import "./_contest.scss";
@import "./postContest.scss";
@import "./_checkout.scss";
@import "./_hero.scss";
@import "./_home.scss";
@import "./_editor.scss";
@import "./_login.scss";
@import "./_footer.scss";
@import "./_about.scss";
@import "./_support.scss";
@import "./_header.scss";
@import "./blog";
@import "./_splitPane.scss";
@import "./_goalRing.scss";
@import "./colors.scss";
@import "../../node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/bootstrap-utilities.scss";

