.drafter-panel {
  min-height: 400px;
  background-color: $white;
  border-top: 6px solid $turq-primary;
}
.sponsor-panel {
  min-height: 400px;
  background-color: $light-gray;
  border-top: 6px solid $turq-primary;
}

.explainer {
  border-bottom: 3px solid $light-gray;
}
