.login-form-area {
  margin-top: 32px;
}

.login-switch {
  float: right;
}

label {
  font-size: 1.5em;
}

.rc-tabs-ink-bar {
  display: none !important;
}

.rc-tabs-nav-list {
  display: flex;
}

.rc-tabs-tab {
  flex: 1;
  color: white;
  padding: 5px;
  text-align: center;
}

.rc-tabs-tab-active {
  border-bottom: 3px solid white;
}
