/* .parent-container {
  display: flex;
  justify-content: space-between;
} */

.organization-content {
  flex-basis: 70%;
}


.chat-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px; /* adjust as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: #D3D3D3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 70%;
    flex-basis: 30%;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
  }
  
  .chat-container form {
    display: flex;
    margin-top: 10px;
  }
  
  .chat-container form input {
    flex: 1;
    margin-right: 10px;
  }
  