@import "./_base.scss";

// Utility color classes

.color-primary {
    color: $turq-primary;
}

.color-secondary {
    color: $turq-secondary;
}

.color-white {
    color: white;
}


// Text color classes

.text-primary { 
    color: black !important;
}

.text-light {
    color: white;
}

.text-grey {
    color: grey;
}

.background-primary {
    background-color: $background-primary !important;
}

.background-secondary {
    background-color: $background-secondary !important;
}

.MuiDrawer-paper {
    background: rgba(80, 78, 78, 0.3) !important;
    backdrop-filter: blur(5px) !important,
}

.lato-font {
    font-family: lato !important;
}
