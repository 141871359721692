// 🎨 Color Palette
$primary: #4F4F4F;
$secondary: #F7F7F7;
$text-primary: #2A2A2A;
$text-secondary: #E70067;
$white: #FFFFFF;
$off-white: #FAFAFA;
$light-gray: #F1F1F1;
$medium-gray: #777777;
$dark-gray: #2B303A;
$black: #000000;

// 🖌 Background Colors
$background-primary: #E3F2FD; // Light pale blue
$background-secondary: #A0C4FF; // Soft blue gradient
$background-color: $background-primary; // Ensures `_about.scss` compiles

// 🟦 Turquoise Theme Colors (Used in `_drafter.scss`)
$turq-primary: #082841;
$turq-secondary: #3A6EA5;

// 🎯 Button Styles
$button-primary: #1F2937;
$button-hover: #374151;

// 🔗 Link Colors
$link-primary: #1F2937;
$link-secondary: #3A6EA5;

// 🖋 Text Colors
$text-color: $text-primary;
$text-muted: $text-secondary;

// 🖋 Fonts
$legal-font: "Times New Roman", Times, serif;
$heading-font: "MontserratBold", sans-serif;
$text-font: "OpenSans", sans-serif;

// 📌 Borders
$border-light: 1px solid $light-gray;
$border-dark: 1px solid $dark-gray;

// 📂 Bootstrap Theme Overrides
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "dark-gray": $dark-gray,
    "turq": $turq-primary,
    "turq-secondary": $turq-secondary
);

// 🔲 Miscellaneous
$shadow-light: 0px 4px 10px rgba(0, 0, 0, 0.1);
$shadow-medium: 0px 6px 15px rgba(0, 0, 0, 0.2);
$shadow-dark: 0px 8px 20px rgba(0, 0, 0, 0.3);
$border-radius: 10px;
