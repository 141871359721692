.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#idCSS);
}

.ring-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 247px;
}

.marker {
  width: 8px;
  height: 65px;
  background-color: black;
  position: absolute;
  bottom: -6.0%;
}

.bar {
  background-color: black;
  height: 22%;
  width: 0.5px;
  flex-basis: 101%;
  position: absolute;
  bottom: -2px;
}

.separator {
  background-color: transparent;
  width: 0.5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
}
.top-banner {
  margin-top: 30px;
}

