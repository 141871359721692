.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}

.react-switch {
    cursor: pointer;
    border-radius: 25px 0 0 25px;
    border: 2px solid #F1F1F1;
    width: 250px;
    height: 50px;
    background: #F1F1F1;
    position: relative;
    transition: background-color 0.2s;
}

.react-switch .react-switch-button {
    position: absolute;
    border-radius: 20px;
    z-index: 1;
    top: 0;
    left: 2px;
    width: 125px;
    height: 45px;
    color: #22D3C1;
    padding-left:0 ;
    padding-right: 0;
    border: 1px solid #22D3C1;
    background: rgba(255, 255, 255, 0.959);
   
    box-shadow: 0 0 2px 0 rgba(14, 0, 0, 0.904);
}

.react-switch-checkbox:checked + .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-button {
    width: 60px;
}

.react-switch-labels {
    display: relative;
    z-index: 0;
    height: 100%;
    font: 14pt "Segoe UI", Verdana, sans-serif;
}

.react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.react-switch-labels span:first-child {
    left: 0;
}
.react-switch-labels span:last-child {
    right: 0;
}
