.about-title {
  font-size: 3em;
}

.about-subtitle {
  font-size: 1.5em;
}

.about-text {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: justify;
}

.about-text-centered {
  margin-top: 40px;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
}

.about-light-row {
  background-color: $light-gray;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  margin-bottom: 3px;;
}

.about-dark-row {
  background-color: $background-color;
}

.round-img {
  border-radius: 50%;
}

.about-task {
  background-color: $dark-gray;
  min-height:70px;
  max-height: 200px;
}

.about-header-text {
  color: $medium-gray;
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.about-text-highlight {
  color: $light-gray;
}

.about-header-text:hover {
  color: $light-gray;
}
