.footer {
  width: 100%;
  background-color: $dark-gray;
  min-height: 48px;
}

.footer-text {
  color: $light-gray;
  line-height: 28px;
}

.footer-link {
  line-height: 28px;
}

.footer-header {
  color: $medium-gray;
}

.product-hunt {
  width: 250px;
  height: 54px;
}

