body p.unnumbered::before, p.unnumbered::before {
    content: "";
}

p.numbered::before {
  counter-increment: linecounter;
  content: counter(linecounter) ". ";
}

p.unnumbered::before {
  counter-increment: none;
  content: "";
}
