.blog-post {
    border-color: #e3e6f0 !important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.blog-container {
    margin-top: 100px;
}

.blog-container img {
    max-width: 100% !important;
}